@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap");

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

:root {
	--primaryBackground: #497cbea9;
	--secondaryBackground: #2f3030;
	--primaryColor: #e0a80d;
	--greyDark: #ffffff;
	--headingColor: #0B42E5;
	--white: #fff;
	--black: #000;
	--sliver: #bac8d3;
}

html {
	scroll-behavior: smooth;
}

body {
	background-color: var(--headingColor);
	color: var(--white);
	font-family: "Montserrat", sans-serif;
}

a {
	color: var(--greyDark);
	text-decoration: none;
	transition: 0.5s;
}

a:hover {
	color: var(--primaryColor);
}

p {
	color: var(--primaryColor);
	font-size: 15px;
	line-height: 30px;
	margin: 20px 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 400;
}

.container {
	max-width: 80%;
	margin: auto;
}

.flexsb {
	display: flex;
	justify-content: space-between;
}

button {
	border: none;
	outline: none;
	background: none;
	font-size: 17px;
	background-color: var(--primaryColor);
	color: var(--white);
	padding: 15px 50px;
	border-radius: 50px;
	margin-top: 20px;
	cursor: pointer;
}

.fontSize {
	font-size: 40px;
}

.primaryBtn {
	background: none;
	border: 2px solid var(--primaryColor);
}

/*---------logo-------------*/
.logo {
	display: flex;
	align-items: center;
}

.logo-text {
	margin-left: 10px;
	font-size: 24px;
	color: var(--white);
	font-weight: 600;
}

/*---------logo-------------*/

/*---------header-------------*/
header {
	height: 10vh;
	line-height: 10vh;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1000;
	padding: 10px 0;
	opacity: 1;
	transition: opacity 0.3s ease-in-out;
}

header a {
	margin-left: 40px;
}

.toggle {
	cursor: pointer;
	color: var(--white);
	display: none;
}

@media screen and (max-width: 768px) {
	header .nav {
		display: none;
	}

	header a {
		width: 100%;
		display: block;
		color: var(--white);
	}

	.toggle {
		display: block;
		font-size: 25px;
		background: none;
	}

	.hideMenu {
		display: block;
		position: absolute;
		top: 10vh;
		left: 0;
		width: 100%;
		background-color: var(--headingColor);
	}
}

/*---------header-------------*/

section {
	height: 90vh;
	display: flex;
	align-items: center;
}

.flex {
	display: flex;
}

/*---------hero-------------*/
.hero {
	position: relative;
	background-image: url("../public/images/home-bg.png");
	background-size: cover;
	background-position: center;
	background-attachment: scroll;
	margin-top: 100px;
}

.heroContent {
	max-width: 50%;
	margin: auto;
	text-align: center;
}

.hero h1 {
	font-size: 60px;
	font-weight: 600;
	color: var(--primaryColor);
}

/*---------hero-------------*/

.heading {
	font-size: 30px;
	color: var(--primaryColor);
	font-weight: 600;
	text-align: center;
	margin-bottom: 60px;
}

/*---------our mission-------------*/
.our_mission {
	position: relative;
	height: 100vh;
	background-image: url("../public/images/our-mission-bg.png");
	background-size: cover;
	background-position: center;
	background-attachment: scroll;
	padding-bottom: 50px;
}

.our_mission .primaryBtn {
	margin-left: 20px;
}

.our_mission .heading {
	text-align: left;
	margin: 0;
}

.left {
	width: 35%;
}

.right {
	width: 65%;
}

.our_mission::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	background-image: url("../public/images/our-mission-bg.png");
	background-size: cover;
	background-attachment: scroll;
	z-index: -1;
	width: 100%;
	height: 80%;
}

/*---------our mission-------------*/

/*---------key features-------------*/
.key_features {
	position: relative;
	height: 100vh;
	background-image: url("../public/images/home-bg.png");
	background-size: cover;
	background-position: center;
	background-attachment: scroll;
}

.key_features .primaryBtn {
	margin-left: 20px;
}

.key_features .heading {
	text-align: center;
	margin: 5;
}

.left {
	width: 35%;
}

.right {
	width: 65%;
}

.key_features .box {
	background-color: var(--primaryBackground);
	padding: 35px;
}

.key_features i {
	color: var(--primaryColor);
}

.key_features h3 {
	margin-top: 20px;
}

.key_features::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	background-image: url("../public/images/home-bg.png");
	background-size: cover;
	background-attachment: scroll;
	z-index: -1;
	width: 100%;
	height: 100%;
}

/*---------key features-------------*/

.grid3 {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 30px;
}

.grid4 {
	grid-template-columns: repeat(4, 1fr);
	height: 50vh;
	place-items: center;
	text-align: center;
}

/*---------contact-------------*/
.contact {
	position: relative;
	padding-top: 100px;
	margin-bottom: 0;
	height: auto;
	min-height: 100vh;
	background-image: url("../public/images/contact-bg.png");
	background-size: cover;
	background-position: center;
	background-attachment: scroll;
}

.contact .right,
.contact .left {
	padding: 20px;
}

.contact .flex input:nth-child(1) {
	margin-right: 10px;
}

.contact button,
.contact textarea,
.contact input {
	width: 100%;
	padding: 20px;
	border: none;
	outline: none;
	margin: 10px 0;
	border-radius: 5px;
}

.contact button:hover {
	background: #d8b34d;
}

/*---------contact-------------*/

/*---------footer-------------*/
footer {
	position: relative;
	text-align: center;
	padding: 20px;
	margin-top: 20px;
}

footer p {
	position: relative;
	z-index: 1;
}

/*---------footer-------------*/

@media screen and (max-width: 768px) {
	.hero {
		height: 100vh;
	}

	.heroContent {
		max-width: 80%;
	}

	.hero::after {
		background-image: url("../public/images/home-bg.png");
		background-size: cover;
		background-attachment: scroll;
		object-fit: cover;
		width: 100%;
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 300vh;
		/* Ensure it covers the full height */
	}

	section {
		height: auto;
		padding-top: 50px;
	}

	.contact .flexsb,
	.key_features .flex {
		flex-direction: column;
	}

	.right,
	.left {
		width: 100%;
	}

	.grid4,
	.grid3 {
		grid-template-columns: repeat(2, 1fr);
	}

	article {
		margin: 0;
		padding-bottom: 50px;
	}
}

@media screen and (min-width: 1024px) {
	.our_mission {
		display: flex !important;
		justify-content: flex-end !important;
		flex-direction: row !important;
		padding: 40px !important;
		align-items: center !important;
	}

	.our_mission .right {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		text-align: right !important;
		width: 60% !important;
		padding-right: 20px !important;
	}

	.our_mission .left {
		display: none !important;
	}
}

@media screen and (max-width: 480px) {
	.our_mission h1 {
		text-align: center;
		font-size: 16px;
		margin-top: 40px !important;
	}

	.our_mission p {
		text-align: center;
		font-size: 14px;
		line-height: 1.4;
		margin-top: 5px !important;
	}

	.our_mission {
		padding: 15px 10px !important;
		background-position: top center !important;
		margin-top: 40px !important;
	}

	.our_mission .left,
	.our_mission .right {
		width: 100% !important;
		text-align: center !important;
		padding: 10px !important;
	}
}

@media screen and (min-width: 1024px) {
	.key_features .heading {
		margin-top: 100px;
		text-align: center;
	}

	.key_features .box {
		width: 80%;
		margin: 20px auto;
		padding: 20px;
		background-color: var(--primaryBackground);
	}

	.grid3 {
		grid-template-columns: repeat(3, 1fr);
		gap: 20px;
	}

	.key_features {
		height: auto;
		padding-bottom: 100px;
	}
}

@media screen and (max-width: 480px) {
	.key_features {
		height: auto !important;
		padding-bottom: 80px !important;
	}

	.grid3 {
		grid-template-columns: 1fr !important;
	}

	.box {
		width: 90% !important;
		margin: 20px auto !important;
		padding: 20px !important;
		box-sizing: border-box !important;
	}

	.key_features .heading {
		margin-top: 120px !important;
		text-align: center !important;
	}

	footer {
		padding-top: 50px !important;
		display: block !important;
		position: relative !important;
		clear: both !important;
	}

	body,
	html {
		height: auto !important;
		min-height: 100vh !important;
	}
}
